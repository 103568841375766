import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Tilt from 'react-tilt'

import theme from './theme'
import Container from './container'

const ShowcaseSection = styled.section``

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  
  @media ${props => props.theme.breakpoints.medium} {
    width: calc(100% - 15vmin);
  }
`

const ShowcaseItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
  margin: 2em 0;
  
  @media ${props => props.theme.breakpoints.medium} {
    flex-direction: column;
  }
`

const ShowcaseItemImage = styled.div`
  width: 30%;
  transition: transform 0.15s ease-in;

  &:hover {
    img {
      box-shadow: ${props => props.theme.shadows.deep};
    }
  }

  img {
    border-radius: 0.3em;
    box-shadow: ${props => props.theme.shadows.shallow};
    transition: all 0.15s ease-in;
  }
  
  @media ${props => props.theme.breakpoints.medium} {
    width: 100%;
  }
`

const ShowcaseContent = styled.div`
  width: 60%;
  padding-top: 1em;
  font-size: 0.8em;
  
  @media ${props => props.theme.breakpoints.medium} {
    padding-top: 2.25em;
    width: 100%;
  }
`

const ShowcaseItem = ({ showcaseItem }) => (
  <ShowcaseItemContainer>
    <ShowcaseItemImage>
      <a href={showcaseItem.url} target="_blank">
        <Tilt
          options={{
            scale: 1.2,
            max: 20,
            perspective: 1200,
            reverse: true,
          }}
        >
          <img src={showcaseItem.image.file.url} />
        </Tilt>
      </a>
    </ShowcaseItemImage>
    <ShowcaseContent>
      <h4>
        <a href={showcaseItem.url} target="_blank">
          {showcaseItem.title}
        </a>
      </h4>
      {showcaseItem.content.content}
    </ShowcaseContent>
  </ShowcaseItemContainer>
)

class Showcase extends React.Component {
  render() {
    const collection = this.props.collection

    return (
      <ShowcaseSection>
        <StyledContainer>
          {collection
            ? collection.map(showcaseItem => (
              <ShowcaseItem
                showcaseItem={showcaseItem}
                key={showcaseItem.id}
              />
            ))
            : 'Error'}
        </StyledContainer>
      </ShowcaseSection>
    )
  }
}

export default Showcase
