import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Container from './container'
import ImageHandler from './image-handler'

const SimpleImageSection = styled.section`
  ${props => props.collapse === 'Top' &&
    'padding-top: 0;'
  }
  ${props => props.collapse === 'Bottom' &&
    'padding-bottom: 0;'
  }
  ${props => props.collapse === 'Top and Bottom' &&
    'padding: 0;'
  }
`

const StyledContainer = styled(Container)`
  width: ${props => props.width ? props.width : 'calc(100% - 10vmin)'};

  img {
    border-radius: .3em;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, .1);
  }
`

class SimpleImage extends React.Component {
  render() {
    return (
      <SimpleImageSection collapse={this.props.collapse}>
        <StyledContainer width={this.props.width}>
          {this.props.image && <ImageHandler image={this.props.image} />}
        </StyledContainer>
      </SimpleImageSection>
    )
  }
}

SimpleImage.propTypes = {
  content: PropTypes.object,
}

export default SimpleImage