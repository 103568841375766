import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import SimpleContent from '../components/simple-content'
import SimpleImage from '../components/simple-image'
import LogoListing from '../components/logo-listing'
import CTA from '../components/cta'
import Showcase from '../components/showcase'
import PricingPlanTable from '../components/pricing-plan-table'
import Grid from '../components/grid'
import HbsptForm from '../components/hbsptForm'
import ContentWithImage from '../components/content-with-image'
import TwoColumnLayout from '../components/two-column-layout'

class Page extends React.Component {
  render() {
    const page = get(this.props, 'data.contentfulPage')
    const components = get(this.props, 'data.contentfulPage.components')
    const description = get(this.props, 'data.contentfulPage.description.childMarkdownRemark.rawMarkdownBody')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const shareImage = page.shareImage ? { property: 'og:image', content: `https:${page.shareImage.file.url}`} : {}
    const twitterCardType = page.shareImage ? 'summary_large_image' : 'summary'
    return (
      <Layout>
        <Helmet
          title={
            `${page.title != 'Home' ? page.title + ' | ' : ''} ${siteTitle}`
          }
          meta={[
            { name: 'description', content: description },
            { property: 'og:type', content: 'website'},
            { property: 'og:title', content: `${page.title != 'Home' ? page.title + ' | ' : ''} ${siteTitle}`},
            { property:'og:description', content: description },
            { name: 'twitter:card', content: twitterCardType},
            { name: 'twitter:site', content: '@starrconspiracy'},
            shareImage,
            { name: 'application-name', siteTitle},
            { name: 'msapplication-TileColor', content: '#FFFFFF'},
            { name: 'msapplication-TileImage', content: '/mstile-150x150.png'}
          ]}
          link={[
            {rel: 'apple-touch-icon', href: '/apple-touch-icon.png'},
            {rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png'},
            {rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png'},
            {rel: 'icon', type: 'image/png', sizes: '192x192', href: '/android-chrome-192x192.png'},
            {rel: 'icon', type: 'image/png', sizes: '512x512', href: '/android-chrome-512x512.png'},
          ]}
        />
        {components ? components.map((component) => {
          switch (component.__typename) {
            case 'ContentfulHero':
              return <Hero {...component} key={component.id} />
            case 'ContentfulSimpleContent':
              return <SimpleContent {...component} key={component.id} />
            case 'ContentfulLogoListing':
              return <LogoListing {...component} key={component.id} />
            case 'ContentfulCta':
              return <CTA {...component} key={component.id} />
            case 'ContentfulSimpleImage':
              return <SimpleImage {...component} key={component.id} />
            case 'ContentfulShowcase':
              return <Showcase {...component} key={component.id} />
            case 'ContentfulPricingPlanTable':
              return <PricingPlanTable {...component} key={component.id} />
            case 'ContentfulGrid':
              return <Grid {...component} key={component.id} />
            case 'ContentfulHubSpotForm':
              return <HbsptForm slug={this.props.pageContext.slug} {...component} key={component.id} />
            case 'ContentfulContentWithImage':
              return <ContentWithImage {...component} key={component.id} />
            case 'ContentfulTwoColumnLayout':
              return <TwoColumnLayout slug={this.props.pageContext.slug} {...component} key={component.id} />
            default:
              return (
                <section className="container" key={component.id}>
                  Unknown component {component.__typename}
                </section>
              )
          }
        }) : <section><div className='container'><h1>Error in loading content</h1></div></section>}
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      title
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      shareImage {
        file {
          url
          contentType
        }
      }
    	components {
        ... on ContentfulHero {
          id
          headline
          content {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            file {
              contentType
              url
            }
          }
          background {
            title
            file {
              contentType
              url
            }
          }
        }
        ... on ContentfulSimpleContent {
          id
          content {
            childMarkdownRemark {
              html
            }
          }
          collapse
          width
        }
        ... on ContentfulLogoListing {
          id
          headline
          collection {
            id
            logo {
              title
              file {
                contentType
                url
              }
            }
            companyName
          }
        }
        ... on ContentfulCta {
          id
          headline
          content {
            childMarkdownRemark {
              html
            }
          }
          buttonContent
          buttonUrl
        }
        ... on ContentfulSimpleImage {
          id
          image {
            title
            file {
              contentType
              url
            }
          }
          collapse
          width
        }
        ... on ContentfulShowcase {
          id
          title
          collection {
            id
            title
            content {
              content
            }
            image {
              file {
                url
              }
            }
            url
          }
        }
        ... on ContentfulPricingPlanTable {
          id
          title
          plans {
            id
            title
            image {
              file {
                contentType
                url
              }
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            price {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on ContentfulGrid {
          id
          title
          collapse
          collection {
            id
            ... on ContentfulSimpleImage {
              title
              image {
              	file {
                  contentType
                  url
                }
                fixed {
                  base64
                  tracedSVG
                }
            	}
            }
          }
        }
        ... on ContentfulHubSpotForm {
          id
          hbsptFormId
          headline
        }
        ... on ContentfulContentWithImage {
          id
          content {
            childMarkdownRemark {
        		  html
        		}
          }
          image {
            file {
              contentType
              url
            }
          }
          imageWidth
          imagePosition
          collapse
        }
        ... on ContentfulTwoColumnLayout {
          column1 {
            ... on ContentfulHubSpotForm {
              id
              hbsptFormId
              headline
            }
            ... on ContentfulContentWithImage {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
              image {
                file {
                  contentType
                  url
                }
              }
              imageWidth
              imagePosition
              collapse
            }
            ... on ContentfulSimpleImage {
                title
                image {
                  file {
                    contentType
                    url
                  }
                }
              }
            ... on ContentfulSimpleContent {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
              collapse
              width
            }

          }
          column2 {
            ... on ContentfulHubSpotForm {
              id
              hbsptFormId
              headline
            }
            ... on ContentfulContentWithImage {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
              image {
                file {
                  contentType
                  url
                }
              }
              imageWidth
              imagePosition
              collapse
            }
            ... on ContentfulSimpleImage {
                title
                image {
                  file {
                    contentType
                    url
                  }
                }
              }
            ... on ContentfulSimpleContent {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
              collapse
              width
            }

          }
          id
          columnWidth
          reorder
          collapse
        }
      }
    }
  }
`
