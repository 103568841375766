import React from 'react'
import styled from 'styled-components'
import HubspotForm from 'react-hubspot-form'
import Container from './container'

const StyledContainer = styled(Container)`
  background: ${props => props.theme.colors.newsprint};
  border-radius: .3em;
  box-shadow: ${props => props.theme.shadows.deep};
  padding: 2em;
  
  @media ${props => props.theme.breakpoints.minMedium} {
    ${props => props.slug === 'contact' ? 'transform: translateY(-8em);':''}
  }

  
  
  form {
    margin: 0 auto;
    max-width: 600px;

    .hs-form-field {
      position: relative;

      input[type='text'],
      input[type='email'],
      input[type='number'],
      input[type='tel'],
      input[type='email'],
      textarea,
      select {
        appearance: none;
        background-color: ${props => props.theme.colors.white};
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: .3em;
        font-weight: 700;
        margin: 0 0 1.25em 0;
        padding: 1em 1.25em;
        font-size: .9em;
        width: 100%;
      }
    }
    
    .hs-richtext {
      p {
        font-size: 0.71em;
        padding-bottom: 1.25em;
      }
    }

    .hs-error-msgs {
      list-style-type: none;
      margin-top: -1em;
      padding: 0;

      li {
        padding: 0;
      }

      label {
        color: ${props => props.theme.colors.snozzberry};
      }
    }

    .hs_error_rollup .hs-error-msgs {
      background-color: ${props => props.theme.colors.pomegranateDarkTrans};
      border: 1px solid ${props => props.theme.colors.snozzberry};
      margin: 0 0 1.25em 0;
      padding: 1em;
      position: static;

      ul {
        padding: 0;
      }

      label {
        font-weight: 700;
      }
    }

    input[type='submit'] {
      display: inline-block;
      background: ${props => props.theme.colors.pomegranate};
      border: 0;
      box-shadow: ${props => props.theme.shadows.shallow};
      border-radius: .3em;
      cursor: pointer;
      font-weight: 700;
      padding: .75em 1em;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      font-size: .9em;
      transition: all ${props => props.theme.animations.smoothTransitionBezier};
      transform: perspective(1px) translateZ(0);
      backface-visibility: hidden;

      &:hover {
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.pomegranateLight};
        box-shadow: ${props => props.theme.shadows.deep};
        transform: scale(1.048);
      }

      &:active {
        background: ${props => props.theme.colors.pomegranateDark};
        box-shadow: none;
      }
    }
  }
`

const Headline = styled.h2`
  color: ${props => props.theme.colors.habenero};
  font-family: ${props => props.theme.fonts.sofiapro};
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 1em;
  text-transform: none;
`


// See all form methods here: https://developers.hubspot.com/docs/methods/forms/advanced_form_options

const HbsptForm = props => <StyledContainer slug={props.slug}>
  {props.headline ? <Headline>{props.headline}</Headline> : ''}
  <HubspotForm
    portalId="372148"
    formId={props.hbsptFormId}
    css=''
  ></HubspotForm>
</StyledContainer>

export default HbsptForm