import React from 'react'

class InlineSVG extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      svg: ''
    };
  }

  componentDidMount() {
    this.fetchSVG(this.props.url)
  }

  async fetchSVG(url) {
    await fetch(url)
      .then(response => response.text())
      .then(returnedSVG => {
        this.setState({
          svg: returnedSVG
        })
      })
      .catch(function (error) { console.log(error); });
  }

  render() {
    return (
      <div dangerouslySetInnerHTML={{
        __html: this.state.svg
      }} />
    )
  }
}

export default InlineSVG