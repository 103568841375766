import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Container from '../components/container.js'
import SimpleContent from '../components/simple-content'
import SimpleImage from '../components/simple-image'
import HbsptForm from '../components/hbsptForm'
import ContentWithImage from '../components/content-with-image'

const TwoColumn = styled.section`
  ${props => props.collapse === 'Top' &&
    'padding-top: 0;'
  }
  ${props => props.collapse === 'Bottom' &&
    'padding-bottom: 0;'
  }
  ${props => props.collapse === 'Top and Bottom' &&
    'padding: 0;'
  }
`

const StyledContainer = styled(Container)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  
  @media ${props => props.theme.breakpoints.small} {
    flex-direction: ${props => props.reorder ? 'column-reverse' : 'column'};
  }
`

const Column1 = styled.div`
  @media ${props => props.theme.breakpoints.minSmall} {
    width: ${props => props.columnWidth ? props.columnWidth + '%' : 'auto'};
  }
`

const Column2 = styled.div`
  @media ${props => props.theme.breakpoints.minSmall} {
    width: ${props => props.columnWidth ? 95 - parseInt(props.columnWidth) + '%' : 'auto'};
  }
`

class TwoColumnLayout extends React.Component {
  render() {
    const column1 = this.props.column1
    const column2 = this.props.column2
    return (
      <TwoColumn collapse={this.props.collapse}>
        <StyledContainer>
          <Column1 columnWidth={this.props.columnWidth}>
            {column1 ? column1.map((component) => {
              switch (component.__typename) {
                case 'ContentfulSimpleImage':
                  return <SimpleImage {...component} key={component.id} />
                case 'ContentfulSimpleContent':
                  return <SimpleContent {...component} key={component.id} />
                case 'ContentfulHubSpotForm':
                  return <HbsptForm slug={this.props.slug} {...component} key={component.id} />
                case 'ContentfulContentWithImage':
                  return <ContentWithImage overridePadding="true" {...component} key={component.id} />
                default: 
                  return (
                    <section className="container" key={component.id}>
                      Unknown component {component.__typename}
                    </section>
                  )
              }
            })  : <div className='container'><h1>Error in loading content</h1></div>}
          </Column1>
          <Column2 columnWidth={this.props.columnWidth}>
            {column2 ? column2.map((component) => {
              switch (component.__typename) {
                case 'ContentfulSimpleImage':
                  return <SimpleImage {...component} key={component.id} />
                case 'ContentfulSimpleContent':
                  return <SimpleContent {...component} key={component.id} />
                case 'ContentfulHubSpotForm':
                  return <HbsptForm slug={this.props.slug} {...component} key={component.id} />
                case 'ContentfulContentWithImage':
                  return <ContentWithImage {...component} key={component.id} />
                default: 
                  return (
                    <section className="container" key={component.id}>
                      Unknown component {component.__typename}
                    </section>
                  )
              }
            })  : <div className='container'><h1>Error in loading content</h1></div>}
          </Column2>
        </StyledContainer>
      </TwoColumn>
    )
  }
}

export default TwoColumnLayout