import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import Container from './container'
import ImageHandler from './image-handler'
import MarkdownParser from './markdown-parser'

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const HeroSection = styled.section`
  ${props => props.background &&
    `background: url(${props.background.file.url}) no-repeat right center;`
  }
  background-size: cover;
  padding: 12em 0 0;
  overflow: hidden;
  
  @media ${props => props.theme.breakpoints.minMedium} {
    padding: 12em 0 4em;
  }

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    img {
      margin-left: 1em;
      max-width: 15em;
    }
  }

  .hero-cta {
    a {
      margin-right: 1em;
    }
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${props => props.theme.breakpoints.medium} {
    flex-direction: column;
    > div:last-of-type {
      padding-top: 4em;
      width: 100%;
      img, svg {
        max-height: 40em;
      }
    }
  }
  @media ${props => props.theme.breakpoints.minMedium} {
    > div {
      width: 55%;
    }

    > div:last-of-type {
      width: 40%;
      
      > div {
        transform: scale(1.2);
      }

      img,
      svg {
        max-height: 40rem;
      }
    }
  }

  svg {
    g#square-large,
    g[data-name="square-large"]{
      animation: ${props => props.theme.animations.floatShort};
    }

    g#square-medium,
    g[data-name="square-medium"] {
      animation: ${props => props.theme.animations.floatMedium};
    }

    g#square-small,
    g[data-name="square-small"] {
      animation: ${props => props.theme.animations.floatHigh};
    }

    g#circle-small,
    g[data-name="circle-small"]{
      animation: ${props => props.theme.animations.floatHigh};
      animation-delay: -1s;
    }
  }

  svg {
    g.g1 {
      rect {
        animation: ${float} 3s ease-in-out infinite;
        animation-delay: 0s;

        &:first-of-type {
          animation: ${float} 6s ease-in-out infinite;
          animation-delay: -2.3s;
        }
      }
      circle {
        animation: ${float} 5s ease-in-out infinite;
        animation-delay: -1s;
      }
    }

    g.g2 {
      animation: ${float} 5s ease-in-out infinite;
      animation-delay: -3s;

      rect {
        animation: ${float} 4s ease-in-out infinite;
        animation-delay: 0s;

        &:first-of-type {
          animation: ${float} 6s ease-in-out infinite;
          animation-delay: -2.3s;
        }
      }
      circle {
        animation: ${float} 5s ease-in-out infinite;
        animation-delay: -1s;
      }
    }

    g.g3 {
      animation: ${float} 6s ease-in-out infinite;
      animation-delay: 0;

      // rect {
      //   animation: ${float} 3s ease-in-out infinite;
      //   animation-delay: 0s;

      //   &:first-of-type {
      //     animation: ${float} 6s ease-in-out infinite;
      //     animation-delay: -2.3s;
      //   }
      // }
      circle {
        animation: ${float} 5s ease-in-out infinite;
        animation-delay: -1s;
      }
    }

    // line {
    //   &:first-of-type {
    //     animation: ${shift} 6s ease-in-out infinite;
    //   }
    //   &:nth-of-type(2) {
    //     animation: ${shift} 6s ease-in-out infinite;
    //     animation-delay: -3s;
    //   }
    // }
  }
`

class Hero extends React.Component {
  render() {
    return (
      <HeroSection background={this.props.background}>
        <StyledContainer>
          <div>
            <h1>{this.props.headline}</h1>
            {this.props.content && <MarkdownParser>{this.props.content.childMarkdownRemark.html}</MarkdownParser>}
          </div>
          <div>
            {this.props.image && <ImageHandler image={this.props.image} />}
          </div>
        </StyledContainer>
      </HeroSection>
    )
  }
}

export default Hero