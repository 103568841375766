import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Link } from 'gatsby'

import Button from './button'

const MarkdownParser = props => (
  <Markdown
    options={{
      overrides: {
        Link: { component: Link },
        Button: { component: Button },
        /** Add any additional overrides here */
        ...props.overrides,
      },
    }}
  >
    {props.children}
  </Markdown>
);

export default MarkdownParser;
