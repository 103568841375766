import React from 'react'
import styled from 'styled-components'

import Container from './container'
import ImageHandler from './image-handler'
import MarkdownParser from './markdown-parser'

const StyledContent = styled.div`
  flex: 1 1 auto;

  h3 {
    margin-bottom: 0;
    font-weight: 400;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: block;
    padding: .4em 0;
    // border-bottom: 2px solid ${props => props.plan === 'Essential' ? props.theme.colors.charcoalDark : props.theme.colors.blueDark};

    &:last-of-type {
      border: 0;
    }

    div {
      display: inline-block;
      background-image: ${props => props.plan === 'Essential' ? 'linear-gradient(to right, ' + props.theme.colors.charcoal + ' 33%, rgba(255,255,255,0) 0%)' : 'linear-gradient(to right, ' + props.theme.colors.white + ' 33%, rgba(255,255,255,0) 0%)'};
      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;
      cursor: help;
    }
  }
`

const StyledTitle = styled.div`
  background: ${props => props.plan === 'Essential' ? props.theme.colors.white : props.theme.colors.blueDark};
  border-radius: .3em;
  padding: 2em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 20rem;

  img,
  svg {
    display: block;
    max-width: 4em;
    max-height: 5em;
    margin: 0 auto 1em;
  }
`

const StyledItems = styled.div`
  padding: 2em;
  border-top: 1px solid ${props => props.plan === 'Essential' ? props.theme.colors.newsprint : props.theme.colors.blue};
`

const Price = styled.div`
  font-size: 2em;
  padding: 0 1em;
  text-align: center;

  a {
    font-size: .5em;
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.pomegranate};

    &:hover {
      background: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.pomegranate};
    }
  }

  del {
    display: block;
    color: ${props => props.theme.colors.pomegranate};
  }

  strong {
    font-size: 2em;
    color: ${props => props.theme.colors.charcoal};
  }
`

const StyledContainer = styled(Container)`
  width: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const PricingPlan = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 0em;
  border-radius: .3em;
  box-shadow: ${props => props.theme.shadows.deep};
  color: ${props => props.plan === 'Essential' ? props.theme.colors.charcoal : props.theme.colors.white};
  background: ${props => props.plan === 'Essential' ? props.theme.colors.white : props.theme.colors.blue};

  &:last-of-type {
    transform: scale(1.05);
    svg {
      line,
      polygon,
      polyline {
        stroke: ${props => props.theme.colors.white};
      }
      rect {
        fill: ${props => props.theme.colors.habenero};
      }
    }
  }

  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
  }
`

class PricingPlanTable extends React.Component {
  render() {
    const collection = this.props.plans

    return (
      <section>
        <StyledContainer>
          {collection ? collection.map((plan) => {
            return (
              <PricingPlan plan={plan.title} key={plan.id}>
                <StyledContent plan={plan.title}>
                  <StyledTitle plan={plan.title}>
                    {plan.image ? <ImageHandler image={plan.image} /> : null}
                    <h3>{plan.title}</h3>
                  </StyledTitle>
                  <StyledItems plan={plan.title} dangerouslySetInnerHTML={{
                    __html: plan.content.childMarkdownRemark.html,
                  }} />
                </StyledContent>
                <Price>
                  <MarkdownParser>
                    {plan.price.childMarkdownRemark.html}
                  </MarkdownParser>
                </Price>
              </PricingPlan>
            )
          }) : 'Error'}
        </StyledContainer>
      </section>
    )
  }
}

export default PricingPlanTable