import React from 'react'
import styled from 'styled-components'
import { transitions } from 'polished'
import Img from 'gatsby-image'
import Waypoint from 'react-waypoint'

import Container from './container'
import ImageHandler from './image-handler'

const GridSection = styled.section`
  ${props => props.collapse === 'Top' && 'padding-top: 0;'}
  ${props => props.collapse === 'Bottom' && 'padding-bottom: 0;'}
  ${props => props.collapse === 'Top and Bottom' && 'padding: 0;'}
`

const StyledWrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  @media ${props => props.theme.breakpoints.minMedium} {
    max-width: 60%;
  }
`

const GridDiv = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin: 2em 0.5em;
  backface-visibility: hidden;
  opacity: ${props => (props.animated ? '1' : '0')};
  transform: ${props => (props.animated ? 'perspective(1px) translateZ(0) scale(1)' : 'scale(1.2)')};
  ${props =>
    transitions(['transform', 'opacity'], props.theme.animations.smoothTransitionBezier)};
  transition-delay: ${props => props.animationDelay}s;

  img,
  svg {
    max-width: 5em;
    max-height: 5em;
    margin: 0 auto 1em;
  }

  @media ${props => props.theme.breakpoints.minMedium} {
    width: 30%;
  }
`

class Grid extends React.Component {
  state = {
    animated: false,
  }

  animateGridItems = () => this.setState({ animated: true })

  render() {
    const animationDelay = { min: 0.25, max: 0.75 }
    const gridItems = this.props.collection
      ? this.props.collection.map(gridItem => (
        <GridDiv
          key={gridItem.id}
          animated={this.state.animated}
          animationDelay={
            Math.random() * (animationDelay.max - animationDelay.min) +
            animationDelay.min
          }
        >
          {gridItem.image &&
            gridItem.title && (
              <div>
                <ImageHandler image={gridItem.image} alt={gridItem.title} />
                <h4>{gridItem.title}</h4>
              </div>
            )}
        </GridDiv>
      ))
      : null

    return (
      <GridSection collapse={this.props.collapse}>
        <Container>
          <Waypoint
            onEnter={this.animateGridItems}
            bottomOffset="20%"
          >
            <div>
              <StyledWrapper>{gridItems}</StyledWrapper>
            </div>
          </Waypoint>
        </Container>
      </GridSection>
    )
  }
}

export default Grid
