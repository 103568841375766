import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from './container'
import Background from '../images/bg-cta.svg'
import Button from './button'

const CTASection = styled.section`
  background: url(${Background}) no-repeat center center;
  background-size: cover;
  text-align: center;
`

const StyledContainer = styled(Container)`
  max-width: 40em;
`

class CTA extends React.Component {
  render() {
    return (
      <CTASection>
        <StyledContainer>
          <h2>{this.props.headline}</h2>
          <div dangerouslySetInnerHTML={{
            __html: this.props.content.childMarkdownRemark.html,
          }} />
          {this.props.buttonUrl ? <Button to={this.props.buttonUrl}>{this.props.buttonContent}</Button> : ''}
        </StyledContainer>
      </CTASection>
    )
  }
}

CTA.propTypes = {
  content: PropTypes.object,
}

export default CTA