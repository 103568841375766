import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import Container from './container'
import ImageHandler from './image-handler'

const SimpleContentWithImageSection = styled.section`
  ${props => props.overridePadding &&
    'padding: 2em 0;'
  }
  ${props => props.collapse === 'Top' &&
    'padding-top: 0;'
  }
  ${props => props.collapse === 'Bottom' &&
    'padding-bottom: 0;'
  }
  ${props => props.collapse === 'Top and Bottom' &&
    'padding: 0;'
  }
`

const StyledContent = styled.div`
  width: 100%;
  @media ${props => props.theme.breakpoints.minMedium} {
    width: ${props => props.imageWidth ? 95 - parseInt(props.imageWidth) + '%' : 'auto'};
  }
`

const StyledImage = styled.div`
  width: 100%;
  @media ${props => props.theme.breakpoints.minMedium} {
    width: ${props => props.imageWidth ? props.imageWidth : 'auto'};
  }

  svg {
    #square-large {
      animation: ${props => props.theme.animations.floatShort};
    }

    #square-medium {
      animation: ${props => props.theme.animations.floatMedium};
    }
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.position === 'Top' &&
    'flex-direction: column;'
  }
  ${props => props.position === 'Bottom' &&
    'flex-direction: column-reverse;'
  }
  ${props => props.position === 'Left' &&
    'flex-direction: row-reverse;'
  }
  
  @media ${props => props.theme.breakpoints.medium} {
    flex-direction: column
  }
`

class ContentWithImage extends React.Component {
  render() {
    return (
      <SimpleContentWithImageSection overridePadding={this.props.overridePadding} collapse={this.props.collapse}>
        <StyledContainer imageWidth={this.props.imageWidth} position={this.props.imagePosition}>
          <StyledContent imageWidth={this.props.imageWidth} dangerouslySetInnerHTML={{
            __html: this.props.content.childMarkdownRemark.html,
          }} />
          <StyledImage imageWidth={this.props.imageWidth}>{this.props.image && <ImageHandler image={this.props.image} />}</StyledImage>
        </StyledContainer>
      </SimpleContentWithImageSection>
    )
  }
}

export default ContentWithImage