import React from 'react'

import InlineSVG from './svg'
import PlaceHolderImg from '../images/placeholder.png';

class ImageHandler extends React.Component {
  constructor(props) {
    super(props);
  }

  setImage() {
    if (this.props.image.file.contentType === 'image/svg+xml') {
      return <InlineSVG url={this.props.image.file.url} />
    }
    else if (this.props.image.file.contentType === 'video/mp4') {
      return (
        <video autoPlay={true} loop={true} muted={true} poster={PlaceHolderImg}><source src={this.props.image.file.url} type={this.props.image.file.contentType} /></video>
      )
    }
    else {
      return <img src={this.props.image.file.url} alt={this.props.image.title} />
    }
  }

  render() {
    return (
      this.setImage()
    )
  }
}

export default ImageHandler