import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Container from './container'
import MarkdownParser from './markdown-parser'

const SimpleContentSection = styled.section`
  ${props => props.collapse === 'Top' &&
    'padding-top: 0;'
  }
  ${props => props.collapse === 'Bottom' &&
    'padding-bottom: 0;'
  }
  ${props => props.collapse === 'Top and Bottom' &&
    'padding: 0;'
  }
`

const StyledContent = styled.div`
  margin: 0 auto;
  
  @media ${props => props.theme.breakpoints.minMedium} {
    width: ${props => props.width ? props.width : 'auto'};
  }
`

class SimpleContent extends React.Component {
  render() {
    return (
      <SimpleContentSection collapse={this.props.collapse}>
        <Container>
          <StyledContent width={this.props.width}>
            <MarkdownParser>{this.props.content.childMarkdownRemark.html}</MarkdownParser>
          </StyledContent>
        </Container>
      </SimpleContentSection>
    )
  }
}

SimpleContent.propTypes = {
  content: PropTypes.object,
}

export default SimpleContent