import React from 'react'
import styled from 'styled-components'
import { transitions } from 'polished'
import Img from 'gatsby-image'
import Waypoint from 'react-waypoint'

import Container from './container'
import ImageHandler from './image-handler'

const LogoListingSection = styled.section`
  // background: white;
`
const Headline = styled.h4`
  text-align: center;
`

const LogoGrid = styled.div`
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;

  > div {
    display: block;
    width: 50%;
    margin: 2em 0;

    @media ${props => props.theme.breakpoints.minSmall} {
      width: 33%;
    }

    @media ${props => props.theme.breakpoints.minMedium} {
      width: 25%;
    }
  }
`

const StyledLogo = styled.div`

  opacity: ${props => (props.animated ? '1' : '0')};
  transform: ${props => (props.animated ? 'perspective(1px) translateZ(0) scale(1)' : 'scale(1.2)')};
  ${props =>
    transitions(['transform', 'opacity'], props.theme.animations.smoothTransitionBezier)};
  transition-delay: ${props => props.animationDelay}s;

  img,
  svg {
    max-height: 3em;
  max-width: 60%;
  }
`

class LogoListing extends React.Component {
  state = {
    animated: false,
  }

  animateLogoItems = () => this.setState({ animated: true })

  render() {
    const headline = this.props.headline
    const collection = this.props.collection

    return (
      <LogoListingSection>
        <Container>
          <Headline>{headline}</Headline>
          <Waypoint
            onEnter={this.animateLogoItems}
            bottomOffset="20%"
          >
            <div>
              <LogoGrid>
                {collection
                  ? collection.map(client => {
                    const animationDelay = { min: 0.25, max: 0.75 }

                    return (
                      <div key={client.id}>
                        {client.logo ? (
                          <StyledLogo
                            animated={this.state.animated}
                            animationDelay={
                              Math.random() *
                              (animationDelay.max - animationDelay.min) +
                              animationDelay.min
                            }
                          >
                            <ImageHandler image={client.logo} />
                          </StyledLogo>
                        ) : (
                            ''
                          )}
                      </div>
                    )
                  })
                  : 'Error'}
              </LogoGrid>
            </div>
          </Waypoint>
        </Container>
      </LogoListingSection>
    )
  }
}

export default LogoListing
